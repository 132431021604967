"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getLandingUrl = exports.default = void 0;
var _router = require("next/router");
var _react = _interopRequireDefault(require("react"));
var _reactIntl = require("react-intl");
var _TenantContext = require("../../context/TenantContext");
var _withAuth = _interopRequireDefault(require("../../hoc/withAuth"));
var _LoginContent = _interopRequireDefault(require("../LoginContent"));
var _MyContainer = _interopRequireDefault(require("../MyContainer"));
var __jsx = _react.default.createElement;
const getLandingUrl = router => {
  if (typeof window === 'undefined') {
    return '';
  }
  // when trying to access a nested page we want to redirect the user to it after logging in
  return router.query.next ? `${window.location.origin}${router.query.next}` : `${window.location.origin}/${router.locale}/my`;
};
exports.getLandingUrl = getLandingUrl;
const Login = () => {
  const {
    brandConfig
  } = (0, _TenantContext.useTenantContext)();
  const router = (0, _router.useRouter)();
  if (localStorage.getItem('deviceId')) {
    router.push(window.location.origin);
    return null;
  }
  return __jsx(_MyContainer.default, null, __jsx(_LoginContent.default, {
    title: __jsx(_reactIntl.FormattedMessage, {
      id: "rriK6q",
      defaultMessage: [{
        "type": 0,
        "value": "Inloggen in Mijn "
      }, {
        "type": 1,
        "value": "island"
      }],
      values: {
        island: brandConfig.name
      }
    }),
    description: __jsx(_reactIntl.FormattedMessage, {
      id: "Yzo049",
      defaultMessage: [{
        "type": 0,
        "value": "Je ontvangt een e-mail met een persoonlijke link naar je account"
      }]
    }),
    landingUrl: getLandingUrl(router),
    brandName: brandConfig.name
  }));
};
var _default = exports.default = (0, _withAuth.default)(Login, {
  onSucces: () => `/my`
});