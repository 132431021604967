"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
const MyContainer = _styledComponents.default.div.withConfig({
  displayName: "MyContainer",
  componentId: "sc-1twho9x-0"
})(["width:100vw;height:100vh;display:flex;align-items:center;flex-direction:column;justify-content:center;background:", ";> div{box-shadow:rgba(59,118,160,0.1) 0px 16px 24px 0px,rgba(59,118,160,0.05) 0px 24px 80px 0px;background:#ffffff;border-radius:0.8rem;padding:4.8rem;}"], ({
  theme
}) => theme.colors.neutral['10']);
var _default = exports.default = MyContainer;